import type { DevtoolsSettings } from '@/plugins/devtools/api'
import type { CustomInspectorState, InspectorNodeTag } from '@vue/devtools-kit'
import type { DeepReadonly, InjectionKey } from 'vue'

export type CustomInspectorStateNode = CustomInspectorState[string][number]

export enum InspectorRootNodes {
  APP = 'APP',
  FORM = 'FORM',
  GRPC = 'GRPC',
  LUA = 'LUA',
  SESSION = 'SESSION'
}
export const noDataTag: InspectorNodeTag = { backgroundColor: 0xff0000, label: 'No Data', textColor: 0xffffff }

export interface DevtoolsController {
  confirm: {
    message: string
    resolve: (val: boolean) => void
  } | null
  /**
   * See {@link DevtoolsSettings}.
   */
  pluginSettings: DeepReadonly<DevtoolsSettings>
}

/**
 * Provides context for all devtools components.
 */
export const CONTROLLER_KEY: InjectionKey<DevtoolsController> = Symbol('controller')
